<template>
  <div class="testing__started">
    <template
      v-if="test && test.QuestionResults && test.QuestionResults.length"
    >
      <testing-item
        v-for="(item, index) in test.QuestionResults"
        :key="index"
        :question="item"
        :is-done="isDone"
    /></template>
  </div>
</template>
<script>
import testingItem from "./testingItem";
export default {
  components: { testingItem },
  props: {
    test: {
      type: Object,
      default: () => {},
    },
    isDone: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
.testing__started {
  padding-right: 12px;
  overflow-y: auto;
  height: calc(100vh - 200px);
}
</style>
